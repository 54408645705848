export const updateUrlParameter = (key: string, value: string) => {
  const origUrl = window.location.href;
  window.history.replaceState('', '', updateParam(origUrl, key, value));
};

const updateParam = (url: string, param: string, value: string) => {
  const re = new RegExp(param + '(.+?)(&|$)', 'g');

  if (!url.match(re)) {
    return url + (url.match('\\?') ? '&' : '?') + param + '=' + value;
  }

  return url.replace(re, param + '=' + value + '$2');
};

export const ensureTrailingSlash = (url: string) =>
  url.endsWith('/') ? url : `${url}/`;
