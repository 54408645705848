import { defineStore } from 'pinia';
import { queryNotifications } from '../../graphql/gateway/queries';
import {
  mutateReadNotification,
  mutateReadNotifications,
  mutateSeenUserNotifications
} from '../../graphql/gateway/mutations';
import { useConfigStore } from './config';

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: [],
    unread: 0,
    unseen: 0,
    total: 0,
    notificationsPerPage: 20,
    cursor: null,
    singlePostUrl: '/community/posts'
  }),
  getters: {
    nextPageAvaialble() {
      return !!this.cursor && this.notifications.length > 0;
    },
    unreadIds() {
      return this.notifications.filter((n) => !n.read_at).map((n) => n.id);
    },
    visibleUnread() {
      return this.unreadIds.length;
    },
    notificationUrl() {
      return (notification) => {
        let url;

        if (notification.subject.__typename == 'Post') {
          url = this.singlePostUrl + '/' + notification.subject.id;
          if (notification.sourceable_type == 'comment') {
            url = url + '/comments/' + notification.sourceable_id;
          }
        } else if (notification.subject.__typename == 'Comment') {
          url =
            this.singlePostUrl +
            '/' +
            notification.subject.post_id +
            '/comments/' +
            notification.subject.id;
        }
        return url;
      };
    }
  },
  actions: {
    getNotifications(nextPage = false) {
      const configStore = useConfigStore();

      const queryArgs = {
        language: configStore.language,
        paginate: {
          limit: this.notificationsPerPage
        }
      };
      if (this.nextPageAvaialble && nextPage) {
        queryArgs.paginate.cursor = this.cursor;
      }
      this.apolloClient
        .query(queryNotifications(queryArgs))
        .then(({ data }) => {
          if (data) {
            this.unread = data.notification.notifications.unread;
            this.unseen = 0;
            this.total = data.notification.notifications.total;
            this.cursor = data.notification.notifications.cursor;
            if (nextPage) {
              this.notifications = [
                ...this.notifications,
                ...data.notification.notifications.notifications
              ];
            } else {
              this.notifications = [
                ...data.notification.notifications.notifications
              ];
            }
          }
        })
        .catch((e) => {
          console.log('Failed to get notifications', e);
        });
    },
    setNotificationAsRead(notificationId) {
      return this.apolloClient.mutate(mutateReadNotification(notificationId));
    },
    async goToNotificationTarget({
      notification,
      isShift,
      isCtrl,
      isCmd,
      isMiddle
    }) {
      if (!notification) return;
      const url = this.notificationUrl(notification);

      if (isShift) {
        window.open(url);
      } else if (isCtrl || isCmd) {
        window.open(url, '_blank');
      }

      if (!notification.read_at) {
        const { data } = await this.setNotificationAsRead(notification.id);
        if (data?.notification?.readNotification?.success) {
          notification.read_at = new Date().getTime();
          this.unread = this.unread - 1;
        }
      }

      if (!isShift && !isCtrl && !isCmd && !isMiddle) {
        window.location.href = url;
      }
    },
    setNotificationsAsSeen() {
      this.apolloClient
        .mutate(mutateSeenUserNotifications)
        .then(({ data }) => {
          if (data?.notification?.seenUserNotifications?.success) {
            this.unseen = 0;
          }
        })
        .catch((e) => {
          console.error('Failed to set all notifications as seen', e);
        });
    },
    markAllAsRead() {
      if (!this.unreadIds.length) return;

      this.apolloClient
        .mutate(mutateReadNotifications({ notifications: this.unreadIds }))
        .then(({ data }) => {
          if (data?.notification?.readNotifications?.success) {
            this.unread = this.unread - this.unreadIds.length;
            this.notifications = this.notifications.map((n) => {
              if (this.unreadIds.includes(n.id)) {
                n.read_at = new Date().getTime();
                return n;
              } else {
                return n;
              }
            });
          }
        })
        .catch((e) => {
          console.log('Failed to set all notifications as read', e);
        });
    }
  }
});
