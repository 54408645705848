import { MutationOptions } from '@apollo/client';
import { gql } from '@/__generated__/gateway';
import {
  AcceptMutation,
  AcceptMutationVariables,
  ReadNotificationMutation,
  ReadNotificationMutationVariables,
  ReadNotificationsInput,
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables,
  SeenUserNotificationsMutation,
  SeenUserNotificationsMutationVariables
} from '@/__generated__/gateway/graphql';

export const mutateReadNotification = (
  notificationId: string
): MutationOptions<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
> => ({
  mutation: gql(`
    mutation ReadNotification($notificationId: ID!) {
      notification {
        readNotification(notification_id: $notificationId) {
          success
        }
      }
    }
  `),
  variables: { notificationId }
});

export const mutateReadNotifications = (
  input: ReadNotificationsInput
): MutationOptions<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
> => ({
  mutation: gql(`
    mutation ReadNotifications($input: ReadNotificationsInput) {
      notification {
        readNotifications(input: $input) {
          success
        }
      }
    }
  `),
  variables: { input }
});

export const mutateSeenUserNotifications = (): MutationOptions<
  SeenUserNotificationsMutation,
  SeenUserNotificationsMutationVariables
> => ({
  mutation: gql(`
    mutation SeenUserNotifications {
      notification {
        seenUserNotifications {
          success
        }
      }
    }
  `)
});

export const mutateAcceptToc = (
  alias: string
): MutationOptions<AcceptMutation, AcceptMutationVariables> => ({
  mutation: gql(`
    mutation Accept($alias: String!) {
      toc {
        accept(alias: $alias) {
          success
        }
      }
    }
  `),
  variables: { alias }
});
