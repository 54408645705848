import { defineStore } from 'pinia';
import { mutateAcceptToc } from '../../graphql/gateway/mutations';
import { userCookies } from '@/shared/constants/cookies';
import { setCookie } from '@/shared/utils/cookie';
import { parseJwt } from '@/shared/utils/jwt';

export const useUserStore = defineStore('user', {
  state: () => ({
    customer: null,
    tocStatus: false,
    showTocAgree: false,
    ownProfile: null,
    followers_count: 0,
    following_count: 0
  }),
  getters: {
    isLogged() {
      return !!this.customer;
    },
    customerDataFromToken() {
      return (token) => {
        const customerData = parseJwt(token);
        return {
          uuid: customerData['cognito:username'],
          first_name: customerData.name,
          last_name: customerData.family_name,
          email: customerData.email
        };
      };
    }
  },
  actions: {
    acceptToc({ onSuccess, onError }) {
      this.apolloClient
        .mutate(mutateAcceptToc('community'))
        .then(({ data }) => {
          if (data.toc.accept.success) {
            this.tocStatus = true;
            return onSuccess();
          }
          onError();
        })
        .catch((e) => {
          console.log('Failed to accept ToC', e);
          onError();
        });
    },
    cleanCustomer() {
      sessionStorage.removeItem(userCookies.CUSTOMER_DATA);
      sessionStorage.removeItem(userCookies.C_ID);
      setCookie(userCookies.C_ID, '', -5);
      this.customer = null;
    },
    setCustomer(token) {
      this.updateCustomerInStorage(token);

      const customer = JSON.parse(
        sessionStorage.getItem(userCookies.CUSTOMER_DATA)
      );
      this.customer = customer;
    },
    updateCustomerInStorage(token) {
      if (token) {
        const customer = this.customerDataFromToken(token);
        sessionStorage.setItem(
          userCookies.CUSTOMER_DATA,
          JSON.stringify(customer)
        );
        sessionStorage.setItem(userCookies.C_ID, token);
      }
    }
  }
});
