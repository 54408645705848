import { defineStore } from 'pinia';
import { useProductStore } from './product';

export const useProductQuantitiesStore = defineStore('product-quantities', {
  state: () => ({
    bulkDiscounts: [],
    quantity: 1,
    showStockNotificationModal: false,
    showQuantityModal: false
  }),
  getters: {
    selectedBulkDiscount() {
      return (arbitraryQuantity) => {
        const target = arbitraryQuantity || this.quantity;
        return this.bulkDiscounts.find((item, idx, items) => {
          const next = items[idx + 1];
          return target >= item.quantity && (!next || target < next.quantity);
        });
      };
    },
    highestDiscountItem() {
      return this.bulkDiscounts.reduce((prev, next) =>
        prev.discount_percentage > next.discount_percentage ? prev : next
      );
    }
  },
  actions: {
    setBulkDiscounts(bulkDiscounts) {
      this.bulkDiscounts = bulkDiscounts;

      if (this.bulkDiscounts.length) {
        const minimalOption = bulkDiscounts.reduce((prev, curr) =>
          parseInt(prev.quantity) < parseInt(curr.quantity) ? prev : curr
        );

        const productStore = useProductStore();
        productStore.currentDiscountPercent = minimalOption.discount_percentage;
      }
    },
    updateQuantity(payload) {
      this.quantity = payload;

      if (this.bulkDiscounts && this.bulkDiscounts.length) {
        const productStore = useProductStore();
        productStore.updatePriceByQuantity();
      }
    }
  }
});
