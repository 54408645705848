import { defineStore } from 'pinia';
import { useUserStore } from './user';
import { useNotificationsStore } from './notifications';
import { queryStarterData } from '../../graphql/gateway/queries';
import { formatMoney } from '@/shared/utils/shopify/money-formatter';

import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';

export const useConfigStore = defineStore('config', {
  state: () => ({
    countryCode: null,
    graphqlHost: null,
    language: 'en',
    scrollableElements: [],
    lastSearch: '',
    minSearch: 3,
    tocPath: '/toc/community',
    pointsMultiplier: 0
  }),
  getters: {
    calculatedPoints() {
      return (value) => {
        // calculates points based on the points multiplier
        // takes product value in cents and returns the points calculated
        // we always round up to the next integer
        return Math.round((value * this.pointsMultiplier) / 100);
      };
    },
    formattedPrice() {
      const format = window.Hobbii?.moneyFormat;
      return (value) => formatMoney(value, format);
    },
    isBodyScrollLocked() {
      return this.scrollableElements.length > 0;
    }
  },
  actions: {
    getStarterData() {
      const userStore = useUserStore();
      if (!userStore.isLogged) return;

      const notificationsStore = useNotificationsStore();
      const userId = userStore.customer?.uuid;

      const queryArgs = {
        language: this.language
      };
      this.apolloClient
        .query(queryStarterData(queryArgs, 'community', userId))
        .then(({ data }) => {
          notificationsStore.unread = data.notification.notifications.unread;
          notificationsStore.unseen = data.notification.notifications.unseen;
          userStore.tocStatus = data.toc.status.success;
          userStore.ownProfile = data.community.profile.user;
          userStore.followers_count = data.community.profile.followers_count;
          userStore.following_count = data.community.profile.following_count;
        })
        .catch((e) => {
          console.log('Failed to get starter data', e);
        });
    },
    clearAllBodyScrollLocks() {
      clearBodyLocks();
    },
    lockBodyScroll(selector) {
      const element = selector ? document.querySelector(selector) : null;

      // Allow for elements with position:sticky with overflowType 'clip'
      lock(element, { overflowType: 'clip' });

      if (element) {
        this.scrollableElements.push(element);
      }
    },
    allowBodyScroll() {
      this.scrollableElements.forEach((el) => {
        unlock(el);
      });
      this.scrollableElements = [];
    }
  }
});
